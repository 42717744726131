import {appWithTranslation} from "next-i18next";
import {Provider} from "react-redux";
import store from "../redux/store";
import "../styles/globals.css";

function MyApp({Component, pageProps}) {
  return (
    <Provider store={store}>
      <Component {...pageProps} />
    </Provider>
  );
}

// export default MyApp
export default appWithTranslation(MyApp /*, nextI18NextConfig */);
