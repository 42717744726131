import {createSlice} from "@reduxjs/toolkit";

export const authData = createSlice({
  name: "authData",
  initialState: {
    accessToken: null,
    refreshToken: null,
    isVisibleModal: false
  },
  reducers: {
    setAuthToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action) => {
      state.refreshToken = action.payload;
    },
    toggleModal: state => {
      state.isVisibleModal = !state.isVisibleModal;
    }
  }
});

// Action creators are generated for each case reducer function
export const {setAuthToken, setRefreshToken, toggleModal} = authData.actions;
export const selectAuthToken = state => state.authData.accessToken;
export const selectRefreshToken = state => state.authData.refreshToken;
export const selectIsVisibleModal = state => state.authData.isVisibleModal;

export default authData.reducer;
